.form-control {
  height: 48px;
  color: map-get($theme, black-505);
  font-size: 15px;
  border-radius: 4px;
  border-color: map-get($theme, grey-c3c);
  padding: 12px 15px 8px;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: map-get($theme, grey-c3c);
  }
  &[readonly] {
    background-color: white;
  }
  &::-webkit-input-placeholder {
    /* Edge */
    color: map-get($theme, grey-818);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: map-get($theme, grey-818);
  }

  &::placeholder {
    color: map-get($theme, grey-818);
  }
}

.form-group {
  position: relative;
  .valueRefresh {
    font-size: 25px;
    color: map-get($theme, black-505);
    @include absolutecenter(y);
    left: 10px;
    height: 25px;
    opacity: 0;
    @include transition(all 0.3s);
  }
  .focused {
    ~ .valueRefresh {
      opacity: 1;
    }
  }
}
