/* ==========================================================================
   #ELEMENTS
   ========================================================================== */
body{
    font-size: 16px;background-color:#f3f3f3;
    font-family:map-get($font, hrg);   
    @include media-breakpoint-down(sm){
        font-size: 14px; 
    }
}

// select color
::-moz-selection {
  /* Code for Firefox */
  color:map-get($theme, white);
  background:map-get($theme, blue);
}

::selection {
   color:map-get($theme, white);
  background:map-get($theme, blue);
}

a{
    @include transition (all 0.5s);
    text-decoration:none; 
    &:hover{
        text-decoration: none;
    }
}