.modal{
	.modal-dialog{ 
		&.w-570{
			max-width:570px;
		}
		.modal-content{
			.closeBtn{
			    position: absolute;
			    right: 6px;
			    font-size: 35px;
			    top: 3px;
			    opacity: 1;
			    color:map-get($theme, blue);
			    z-index: 999;
			    text-shadow: none;
			    background: transparent; 
			    border-radius:50%;
			    // i{background-color:map-get($theme, white);border-radius:50%;} 
			}
			.modal-header{
				.close{
					color:map-get($theme, grey-b9c);padding:15px 0 0;margin-left: 0px;opacity:1;  
					i{font-size:28px;}
					&:hover{
						color:map-get($theme, black-505);   
					}
				}
				.modal-title{
					font-size:18px;color:map-get($theme, black-505);margin:15px auto 0;    
				}
			}
			.modal-body{
               padding:25px; 
               .btn-row{
               	margin-top:20px; 
               }
			}
		}
	}
}
.pdfModal{
	.modal-dialog{
		max-width:325px;  
	}
	.modal-body{
		ul{
			li{
				a{ 
					font-size:14px;color:map-get($theme, black-2b3);
					display:block;font-family:map-get($font, neo-md);
				}
				&:not(:last-child){
					a{border-bottom:1px solid rgba(#000, 0.1);padding-bottom:12px;margin-bottom:12px;} 
					
				} 
			}
		}
	}
}
.sessionout-modal{
	.modal-body{ 
		p{font-size:16px;color:map-get($theme, 505);margin:20px 0 30px; } 
	}
}

.request-modal{
	.modal-body{
		h3{font-size:18px;color:map-get($theme, grey-505);margin:20px 0 10px;}
		p{font-size:16px;color:map-get($theme, grey-777);margin-bottom: 30px; } 
	}
}