.btn:focus,.btn.active,input:focus,input:hover,button:focus,.btn:active,button:hover,button:active,button.active,.selectpicker:focus,a:focus,
.btn:active:focus {
    @include no-outline();box-shadow: none !important;
}

.overlay-full{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;

    h4{
        color: white;
    }
}

.pg-h-100{
    min-height: 100vh;
}

.disabled-link {
    pointer-events: none;
  }

:focus {
    outline: none;
}

textarea{
    resize:none;
}

// font and colors
.blue-color{color:map-get($theme, blue);}

.font-hrg{font-family:map-get($font, hrg) !important;}
.font-hbd{font-family:map-get($font, hbd) !important;}
.font-neomd{font-family:map-get($font, neo-md) !important;}

//background color
.bg-grey{
    background-color:#edf0f4; 
}

.commonTooltip{
    padding: 5px 10px 3px !important; 
    z-index:999999 !important;  
    background-color:#000 !important; 
}

//common padding for inner pages
main{
  &.inner-page{
    padding-bottom: 60px;
    @include media-breakpoint-down(md){
        padding-bottom: 40px;
    }
    @include media-breakpoint-down(sm){
        padding-bottom: 30px;
    }
  }
}
//loader for button

