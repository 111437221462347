.header {
	position: fixed;
	top: 15px;
	left: 0;
	width: 100%;
	padding: 0 15px;
	display: flex;
	align-items: center;
	z-index: 1;
	.logo {
		background: map-get($theme, white);
		border-radius: 5px;
		margin-right: 15px;
		padding: 12px 15px 9px;
		@include box-shadow(0, 0, 5px, rgba(#000, 0.05));
		h1 {
			font-size: 20px;
		}
	}
	ul {
		background: map-get($theme, white);
		border-radius: 5px;
		margin-right: 15px;
		padding: 10px 15px 9px;
		@include box-shadow(0, 0, 5px, rgba(#000, 0.05));
		li {
			height: 26px;
			a {
				color: map-get($theme, grey-7e8);
				cursor: default;
				opacity: 0.4;
				text-decoration: none;
				i {
					font-size: 26px;
				}
				&.active {
					cursor: pointer;
					opacity: 1;
					&:hover {
						color: map-get($theme, blue);
					}
				}
			}
			&:not(:last-child) {
				margin-right: 20px;
				position: relative;
				&:after {
					content: "";
					width: 1px;
					height: 23px;
					background: map-get($theme, grey-d2d);
					position: absolute;
					right: -8px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
	.btn-primary {
		height: 45px;
		display: flex;
		align-items: center;
	}
	@media (max-width: 1399px) {
		.logo {
			padding: 13px 13px 8px;
			h1 {
				font-size: 18px;
			}
		}
		ul {
			padding: 10px 12px 9px;
			li {
				height: 23px;
				a {
					i {
						font-size: 24px;
					}
				}
			}
		}
		.btn-primary {
			height: 42px;
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}
