.contextMenu {
    background-color: map-get($theme, white);
    border-radius: 6px; 
    padding: 10px 15px; 
    position: absolute;
    top: 100px;
    left: 200px;
    @include box-shadow(0, 0, 5px, rgba(#000, 0.1));

    .mainMenu {
        margin-bottom: 0;

        >li {
            height: 22px;

            select {
                font-size: 16px;
                border: 0; 
                box-shadow: none;
                height: 30px;
                margin-top: -4px;  
                width: 50px;
                background-position: right 5px center;
            }

            a {
                font-size: 24px;
                color: map-get($theme, grey-7e8);
                display: block;

                &:hover {
                    color: map-get($theme, blue);
                }

                &.fontbd {
                    margin-top: 8px;
                    &:not(:last-child){
                        margin-right:8px;
                    }
                }
            }

            &:not(:last-child) {
                padding-right: 12px;
                border-right: 1px solid map-get($theme, grey-d2d);
                margin-right: 12px;
            }

            &.dropdown {
                .dropdown-toggle {
                    display: block;
                    margin-top:-3px;
                    &:after {
                        display: none;
                    }
                }

                &.font {
                    .dropdown-toggle {
                        font-size: 17px;
                        max-width: 85px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin-top:-1px;
                    }

                    .dropdown-menu {
                        min-width: 100px;
                        border-color: #D2D2D2;
                        padding: 9px 0px;
                        margin-top: 8px;
                        border-radius: 0px 0 4px 4px;

                        .dropdown-item {
                            font-size: 16px;
                            height: auto;
                            padding: 0 12px;

                            &:not(:last-child) {
                                margin-bottom: 8px;
                            }

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }

                .colorDropdown {
                    min-width: 163px;
                    padding: 8px 9px 0px 9px;
                    margin-top: 0;

                    .colorList {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -4px;

                        li {
                            vertical-align: middle;
                            height: 28px;
                            flex: 0 0 20%;
                            max-width: 20%;
                            padding: 0 4px;

                            &:not(:last-child) {
                                margin-right: 0;
                                margin-bottom: 0;
                            }

                            a {
                                padding: 0 !important;
                                height: 20px;
                                width: 22px;
                                display: inline-block;
                                border: 0;
                                border-radius: 0;
                                position: relative;

                                &:after {
                                    content: "";
                                    height: 26px;
                                    width: 28px;
                                    position: absolute;
                                    top: -3px;
                                    left: -3px;
                                    border: 2px solid transparent;
                                }

                                &.active {
                                    &:after {
                                        border-color: map-get($theme, blue);
                                    }
                                }

                                &:hover {
                                    background-color: transparent;
                                }
                            }

                            label {
                                cursor: pointer;
                                margin-bottom: 0;
                                color: map-get($theme, grey-7e8);
                                height: 29px;

                                &:hover,
                                &.active {
                                    color: map-get($theme, blue);
                                }

                                &.addColor {
                                    position: relative;
                                    margin-left: 0;

                                    input[type="color"] {
                                        position: absolute;
                                        height: 100%;
                                        width: 100%;
                                        opacity: 0;
                                        cursor: pointer;
                                    }

                                    i {
                                        font-size: 22px;
                                        margin-top: -1px;
                                        display: inline-block;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                label {
                                    margin: 0 4px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}