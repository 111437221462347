.btn{
    padding:12px 25px 10px;cursor:pointer;font-family:map-get($font, neo-md); border-radius: 4px;font-size: 14px;
    &.w_170{
       min-width:170px;  
    }
    &.w_120{
       min-width:120px; 
    }
    &.btn-primary{
        border-color:map-get($theme, blue);color:map-get($theme, white);background-color:map-get($theme, blue);
        &:hover,&:focus,&:active,&:not(:disabled):not(.disabled):active {
            border-color:darken(map-get($theme, blue), 5%);
            background: darken(map-get($theme, blue), 5%);color:map-get($theme, white); 
        }
        &.disabled{
            border-color:map-get($theme, grey-b9c);background-color:map-get($theme, grey-b9c);pointer-events:none; 
           &:hover,&:focus,&:active,&:not(:disabled):not(.disabled):active {
            border-color:darken(map-get($theme, grey-b9c), 5%);
            background: darken(map-get($theme, grey-b9c), 5%);
            }
        }
    }

    &.btn-dark{
        border-color:map-get($theme, black-505);color:map-get($theme, white);background-color:map-get($theme, black-505);
        &:hover,&:focus,&:active,&:not(:disabled):not(.disabled):active {
            border-color:darken(map-get($theme, black-505), 5%);
            background: darken(map-get($theme, black-505), 5%);color:map-get($theme, white);  
        }
    }

     &.btn-white{
        border-color:map-get($theme, grey-c3c);color:map-get($theme, black-505);background-color:map-get($theme, white);
        &:hover,&:focus,&:active,&:not(:disabled):not(.disabled):active {
            border-color:darken(map-get($theme, grey-c3c), 5%);
            background:rgba(map-get($theme, blue), 0.3);color:map-get($theme, black-505);   
        }
    }
}
