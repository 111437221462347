aside {
	background-color: #fff;
	width: 70px;
	position: fixed;
	left: 15px;
	top: 76px;
	z-index: 1;
	border-radius: 8px;
	padding: 15px;
	@include box-shadow(0, 0, 5px, rgba(#000, 0.1));

	ul {
		li {
			a {
				padding: 5px 6px !important;
				color: map-get($theme, grey-7e8) !important;
				display: block;
				border: 1px solid map-get($theme, grey-d2d);
				border-radius: 6px;

				&:hover,
				&.active,
				&[aria-expanded="true"] {
					color: map-get($theme, white) !important;
					background-color: map-get($theme, blue);
				}

				i {
					font-size: 26px;
					display: inline-block;
					vertical-align: middle;

					&.icon-crop_free_active {
						font-size: 18px;
					}
				}
			}

			&:not(:last-child) {
				margin-right: 0px;
				margin-bottom: 15px;
			}

			.dropdown-toggle {
				&:after {
					display: none;
				}
			}

			.dropdown-menu {
				min-width: 134px;
				padding: 4px 6px;
				border-color: map-get($theme, grey-d2d);
				left: 60px !important;
				top: -38px !important;

				.dropdown-item {
					display: inline-block;
					padding: 0 !important;
					width: auto;
					margin-bottom: 4px;
					background-color: transparent;
					color: map-get($theme, grey-7e8);

					&:nth-child(8) {
						margin-right: 0px;
					}

					&:not(:last-child) {
						margin-right: 5px;
					}

					&:hover,
					&.active {
						color: map-get($theme, blue) !important;
					}
				}

				.colorList {
					margin: 12px -4px 0;
					padding-top: 13px;
					position: relative;
					display: flex;
					flex-wrap: wrap;
					&:after {
						content: "";
						width: calc(100% - 8px);
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						height: 1px;
						position: absolute;
						background-color: map-get($theme, grey-d2d);
					}
					li {
						vertical-align: middle;
						height: 28px;
						flex: 0 0 20%;
						max-width: 20%;
						padding: 0 4px;
						&:not(:last-child) {
							margin-right: 0;
							margin-bottom: 0;
						}

						a {
							padding: 0 !important;
							height: 20px;
							width: 22px;
							display: inline-block;
							border: 0;
							border-radius: 0;
							position: relative;

							&:after {
								content: "";
								height: 26px;
								width: 28px;
								position: absolute;
								top: -3px;
								left: -3px;
								border: 2px solid transparent;
							}

							&.active {
								&:after {
									border-color: map-get($theme, blue);
								}
							}

							&:hover {
								background-color: transparent;
							}
						}

						label {
							cursor: pointer;
							margin-bottom: 0;
							color: map-get($theme, grey-7e8);
							height: 29px;

							&:hover,
							&.active {
								color: map-get($theme, blue);
							}

							&.addColor {
								position: relative;
								margin-left: 0;

								input[type="color"] {
									position: absolute;
									height: 100%;
									width: 100%;
									opacity: 0;
									cursor: pointer;
								}

								i {
									font-size: 23px;
									margin-top: -1px;
									display: inline-block;
								}
							}
						}

						&:nth-child(2) {
							label {
								margin: 0 4px 0 0;
							}
						}
					}
				}

				.range {
					border-top: 1px solid map-get($theme, grey-d2d);
					margin-top: 13px;
					padding-top: 8px;

					span {
						font-size: 13px;
						color: map-get($theme, grey-818);
					}

					.slidecontainer {
						width: 100%;

						.slider {
							-webkit-appearance: none;
							appearance: none;
							width: 100%;
							height: 4px;
							background: map-get($theme, grey-c3c);
							outline: none;
							opacity: 1;
							@include transition(all 0.3s);
							border-radius: 35px;
						}

						.slider::-webkit-slider-thumb {
							-webkit-appearance: none;
							appearance: none;
							border-radius: 50%;
							width: 12px;
							height: 12px;
							background: map-get($theme, black-505);
							cursor: pointer;
						}

						.slider::-moz-range-thumb {
							-webkit-appearance: none;
							appearance: none;
							border-radius: 50%;
							width: 12px;
							height: 12px;
							background: map-get($theme, black-505);
							cursor: pointer;
						}
					}
				}

				&.shapeDropdown {
					padding: 4px 2px 0 6px;
				}

				&.redoDropdown {
					min-width: 40px;
					text-align: center;

					.dropdown-item {
						height: 28px;
						display: flex;
						align-items: center;
						justify-content: center;

						i {
							margin-top: 1px;
						}
					}
				}

				&.downloadDrop {
					padding: 0;
					min-width: 145px;

					.dropdown-item {
						padding: 10px 15px 7px !important;
						font-size: 14px;
						font-family: map-get($font, neo-md);
						color: map-get($theme, black-2b3);
						display: block;
						margin: 0;
						border-radius: 0;

						&:hover,
						&.active {
							color: map-get($theme, blue);
						}

						&:not(:last-child) {
							border-bottom: 1px solid map-get($theme, grey-d2d);
						}
					}
				}

				&.arrowDropdown {
					min-width: 170px;
					padding: 10px 10px 7px;

					.dropdown-item {
						margin-bottom: 0px;
						&:not(:last-child) {
							margin-right: 9px;
						}
					}
				}

				&.noteDropdown {
					min-width: 222px;
					padding: 6px 6px;

					ul {
						display: flex;
						flex-wrap: wrap;

						li {
							margin-bottom: 0;

							&:not(:last-child) {
								margin-right: 0;
							}

							a {
								border: 0;
								padding: 6px 6px !important;

								img {
									width: 40px;
									@include transition(all 0.3s);

									&:hover {
										transform: scale(1.2);
									}
								}

								&:hover {
									background-color: transparent;
								}
							}
						}
					}
				}

				&.colorDropdown {
					min-width: 119px;
					padding: 6px 6px 3px;

					ul {
						li {
							vertical-align: middle;
							height: 32px;

							&:not(:last-child) {
								margin-right: 4px;
								margin-bottom: 0;
							}

							a {
								padding: 0 !important;
								margin: 3px;
								height: 22px;
								width: 26px;
								display: inline-block;
								border: 0;
								border-radius: 0;

								&:hover {
									background-color: transparent;
								}
							}

							label {
								cursor: pointer;
								margin-bottom: 0;
								color: map-get($theme, grey-7e8);
								height: 32px;

								input[type="file"] {
									display: none;
								}

								i {
									font-size: 34px;

									&.icon-docicon2 {
										font-size: 25px;
									}
									&.icon-images {
										font-size: 28px;
									}
								}

								&:hover,
								&.active {
									color: map-get($theme, blue);
								}
							}

							&:nth-child(2) {
								label {
									margin: 0 4px 0 0;
								}
							}
						}
					}

					&.fileUpload {
						min-width: 121px;
						padding-bottom: 0;

						ul {
							li {
								height: auto;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 1399px) {
		width: 60px;
		padding: 10px;

		ul {
			li {
				a {
					padding: 5px 7px 4px;

					i {
						font-size: 24px;
					}
				}

				&:not(:last-child) {
					margin-bottom: 11px;
				}

				.dropdown-menu {
					&.shapeDropdown {
						min-width: 127px;
					}

					&.redoDropdown {
						padding: 3px 6px;
					}
				}
			}
		}
	}
}
