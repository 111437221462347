/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

//font-reg
@font-face {
  font-family: 'HelveticaNeueW23forSKY-Reg';
   src: url('../../fonts/HelveticaNeueW23forSKY-Reg.eot?#iefix') format('embedded-opentype'),  
  url('../../fonts/HelveticaNeueW23forSKY-Reg.woff') format('woff'),
   url('../../fonts/HelveticaNeueW23forSKY-Reg.ttf')  format('truetype'),
    url('../../fonts/HelveticaNeueW23forSKY-Reg.svg#HelveticaNeueW23forSKY-Reg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeueW23foSKY-Bd';
   src: url('../../fonts/HelveticaNeueW23foSKY-Bd.eot?#iefix') format('embedded-opentype'), 
   url('../../fonts/HelveticaNeueW23foSKY-Bd.woff') format('woff'),
    url('../../fonts/HelveticaNeueW23foSKY-Bd.ttf')  format('truetype'), 
   url('../../fonts/HelveticaNeueW23foSKY-Bd.svg#HelveticaNeueW23foSKY-Bd') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'NeoSansW23-Medium';
  src: url('../../fonts/NeoSansW23-Medium.eot?#iefix') format('embedded-opentype'), 
   url('../../fonts/NeoSansW23-Medium.woff') format('woff'), 
   url('../../fonts/NeoSansW23-Medium.ttf')  format('truetype'), 
   url('../../fonts/NeoSansW23-Medium.svg#NeoSansW23-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
