// .homePage {
    .mainWrapper {
        position: relative;
        overflow: hidden;
        height: 100vh;
        .canvas-container{
            width:100vw !important;height:100vh !important;
            // canvas{width:100vw !important;height:100vh !important;}
        }
    }
// }

.getStarted {
    &__inner {
        min-height: 100vh;
    }
    &__box {
        background-color: map-get($theme, white);
        width: 400px;
        padding: 40px;
        border-radius: 5px;
        h1 {
            font-size: 24px;
        }
    }
}
