
// color variables
$color-palette:(
    theme : (
	     white       : #fff, 
	     grey-c3c  	 : #c3cad6,
	     grey-dfe    : #dfe1e6,
         grey-d2d    : #D2D2D2,
	     grey-b9c    : #b9c2cb,
	     grey-818    : #818c9c,
         grey-777    : #777d84,
         grey-7e8    : #7E8388,
	     blue        : #3561E8, 
	     black-505   : #50555a,
         black-2b3   : #2b3138,
         black-000   : #000000,
    )
);

$theme: map-get($color-palette, theme);

// fonts
$font-family : (
    font : (
        hrg    : 'HelveticaNeueW23forSKY-Reg',
        hbd    : 'HelveticaNeueW23foSKY-Bd',
        neo-md : 'NeoSansW23-Medium',       
    )
);

$font: map-get($font-family, font);


$imageurl       :    "../images";
$fonturl        :    "/assets/fonts";